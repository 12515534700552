
















































































































































import { LeadplusForm } from '@/api/leadplus/types'
import { copyToClipboard } from '@/utils/copyToClipboard'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'
import { Component, Vue } from 'vue-property-decorator'

@Component({
    name: 'IntegrationFormModal',
})
export default class extends Vue {
    formId = ''
    formType = 'popover'
    form: LeadplusForm | null = null
    private subtitle = 'Copia questo codice e incollalo prima del tag "</body>" su ogni pagina del tuo sito web'
    private open = false
    public loading = false

    $refs!: any

    get code () {
        return '<!-- Form e Popup INIZIO codice integrazione per il form --><scr' + 'ipt>(function(i,s,o,g,r,a,m){i["LeadplusObject"]=r;if(i[r])return;i[r]=function(){if(!i[r].q)i[r].q=[];i[r].q.push(arguments)};i[r].l=1*new Date;a=s.createElement(o);m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)})(window,document,"script","' + this.leadplusApi + '/render-without-groups/app.js","rf");rf("' + this.formId + '")</scr' + 'ipt><!-- Form e Popup FINE codice integrazione per il form -->'
    }

    get code2 () {
        return `<a class="fogForm" href="#${this.formId}">Registrati alla newsletter</a>`
    }

    get code3 () {
        return `<div class="fogForm" id="fogFormEmbedded_${this.formId}"></div>`
    }

    get leadplusApi () {
        return process.env.VUE_APP_LEADPLUS_OLD_API || ''
    }

    get publicUrl () {
        if (!this.formId) return ''
        const props = this.$router.resolve({
            name: 'leadplusLive',
            params: { formId: this.formId },
        })

        return window.location.origin + props.href
    }

    public openModal (form: LeadplusForm) {
        this.formId = form._id
        this.formType = form.theme.type
        this.form = form
        this.loading = false
        this.open = true
    }

    public closeModal () {
        this.open = false
    }

    private copyToClipboard (text: string) {
        try {
            copyToClipboard(text)
            this.$root.$vsToast({
                heading: 'Codice copiato con successo',
                aspect: VsToastAspectEnum.success,
                timeout: 3000,
            })
        } catch (error) {
            this.$root.$vsToast({
                heading: 'Errore durante la copia del codice',
                aspect: VsToastAspectEnum.alert,
                timeout: 3000,
            })
        }
    }

    clickDomainRules () {
        this.closeModal()
        this.$emit('click-domain-rules', this.form)
    }
}

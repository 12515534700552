



















































import { updateFormById } from '@/api/leadplus/forms'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'
import { Component, Vue } from 'vue-property-decorator'

@Component({
    name: 'FormNameModal',
})
export default class extends Vue {
    private id = ''
    private name = ''
    private open = false
    public loading = false
    $refs: any

    public async save () {
        this.loading = true
        const valid = await this.$refs.nameForm.validate()
        if (!valid) return
        try {
            await updateFormById(this.id, {
                name: this.name,
            })
            this.$emit('updated', {
                id: this.id,
                name: this.name,
            })
            this.$root.$vsToast({
                heading: 'Nome del form modificato con successo',
                aspect: VsToastAspectEnum.success,
                timeout: 3000,
            })
            this.open = false
        } catch (e) {
            this.$root.$vsToast({
                heading: 'Errore durante la modifica del nome',
                aspect: VsToastAspectEnum.alert,
                timeout: 3000,
            })
        }
    }

    public openModal (name: string, id: string) {
        this.loading = false
        this.name = name
        this.id = id
        this.open = true
        setTimeout(() => {
            this.$refs.nameInput.$children[0].$refs.input.focus()
        }, 200)
    }

    public closeModal () {
        this.open = false
    }
}
